import Component from '@glimmer/component';
import { action, set } from '@ember/object';
import eventsLogger from 'garaje/utils/decorators/events-logger';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { dropTask, task } from 'ember-concurrency';
import { defer } from 'rsvp';
import { underscore } from '@ember/string';
import employeesSearcherTask from 'garaje/utils/employees-searcher';
import { alias } from 'macro-decorators';
import { getFlattenedEmployees } from 'garaje/utils/resource-overview';
import { cached } from 'tracked-toolbox';
import { PENDING_UNASSIGNMENT } from 'garaje/utils/resource-map';

/**
 * @param {Model<MapFeature>}           feature
 * @param {Function}                    removeFeature
 * @param {Function}                    back
 * @param {Object}                      additionalDetails
 * @param {Object}                      availableResources
 * @param {boolean}                     isDraftMap
 */

const DESK_ATTRIBUTES_TO_UPDATE_RESOURCE_OVERVIEW = ['name', 'assignedTo', 'enabled'];

@eventsLogger
class DeskForm extends Component {
  @service state;
  @service store;
  @service abilities;
  @service resourceOverview;
  @service metrics;

  @alias('state.billingCompany.blockSelfServe') blockSelfServe;

  @tracked neighborhoodSearchValue = '';
  @tracked employeeSearchValue = '';
  @tracked deskNameError = [];
  @tracked neighborhoodError = [];
  @tracked employeeError = [];
  @tracked searchedEmployees = [];
  @tracked selectedEmployee = '';

  get selectedDeskResource() {
    const { feature } = this.args;
    if (feature.isDeleted) {
      return null;
    }
    if (feature.isNew) {
      return feature.desk;
    }
    return this.store.peekRecord('desk', feature.externalId);
  }

  @cached
  get selectedDeskResourceInsight() {
    const { additionalDetails } = this.args;
    const insight = additionalDetails.resourceInsights.find((insight) => {
      return insight.id === this.selectedDeskResource.id && insight.type === 'DESK';
    });
    const insightDisplay = insight?.utilization ?? 0;
    return `${insightDisplay}% utilization` || null;
  }

  get hasDeskError() {
    return !!(this.deskNameError.length || this.neighborhoodError.length || this.employeeError.length);
  }

  get cannotManageDesks() {
    return this.abilities.cannot('manage-desk desks');
  }

  @action
  editDeskName(value) {
    set(this.args.feature, 'name', value);
    this.editDesk(this.selectedDeskResource, 'name', value);
    this.validateDeskName(value);
  }

  @action
  updateEmployeeOverviewPanel(employeeId, assignedDesks) {
    this.args.updateEmployeeOverview?.(employeeId, assignedDesks);
  }

  @action
  editDesk(desk, attr, value) {
    const { feature, updateResourceOverview } = this.args;
    if (value === '') {
      set(desk, attr, null);
    } else {
      set(desk, attr, value);
    }
    if (updateResourceOverview) {
      if (DESK_ATTRIBUTES_TO_UPDATE_RESOURCE_OVERVIEW.includes(attr)) {
        updateResourceOverview('update', feature);
      }
    }
  }

  // finds the desks assigned to the given employeeId from the GQL response, and appends the assignedTo field for each of them
  getAssignedDesksForEmployee(employeeId) {
    return getFlattenedEmployees(this.args.gqlEmployees?.assigned)
      .filter((it) => parseInt(it.id) === parseInt(employeeId))
      .map((it) => {
        return it.assignedDesks.map((assignedDesk) => {
          return {
            ...assignedDesk,
            assignedTo: it.email,
          };
        });
      })
      .flat();
  }

  @action
  onDeleteDesk() {
    const { feature, removeFeature } = this.args;
    const { updateActiveDesksCount } = this.args.additionalDetails;
    if (this.selectedDeskResource.enabled) {
      updateActiveDesksCount(-1);
    }
    const employee = this.store
      .peekAll('employee')
      .find((employee) => employee.email === this.selectedDeskResource.assignedTo);

    if (employee) {
      const remainingAssignedDesksForEmployee = this.getAssignedDesksForEmployee(employee.id)?.filter(
        (it) => parseInt(it.id) !== parseInt(this.selectedDeskResource.id),
      );
      this.updateEmployeeOverviewPanel(employee.id, remainingAssignedDesksForEmployee);
    }
    this.selectedDeskResource.deleteRecord();
    removeFeature(feature);
  }

  onDeskResourceChangeTask = task({ drop: true }, async () => {
    if (this.selectedDeskResource) {
      this.setSelectedEmployeeTask.perform();
      this.validateDeskName(this.selectedDeskResource.name);
      this.validateDeskNeighborhood();
      this.validateAssignedEmployeeTask.perform();
    }
  });

  @action
  setDeskEnabled(value) {
    const { updateActiveDesksCount } = this.args.additionalDetails;
    const { isDraftMap, feature } = this.args;
    this.editDesk(this.selectedDeskResource, 'enabled', value);

    if (value) {
      this.logEnabled({
        resource_type: underscore(feature.type),
        isDraft: isDraftMap,
      });
    } else {
      this.logDisabled({
        resource_type: underscore(feature.type),
        isDraft: isDraftMap,
      });
    }

    updateActiveDesksCount(value ? 1 : -1);
  }

  validateDeskName(name) {
    const { desks } = this.args.additionalDetails;
    if (name === '' || name === null) {
      this.deskNameError = ['Please enter a desk name'];
    } else if (
      desks.any(
        (deskToCompare) =>
          deskToCompare.name === name && deskToCompare !== this.selectedDeskResource && !deskToCompare.isDeleted,
      )
    ) {
      this.deskNameError = ['Desk name already exists'];
    } else {
      this.deskNameError = [];
    }
    this.setDeskHasError();
  }

  setDeskHasError() {
    const { feature } = this.args;
    // (ar) this is to ensure we don't try setting an attribute on a feature that was destroyed
    // since we manually destroy dirty records in the bulk features create flow before inserting new ones,
    // we want to ensure this function does not proceed if `feature` is set to one of those destroyed records
    if (feature.isDestroyed) {
      return;
    }
    if (this.hasDeskError) {
      set(feature, 'hasError', true);
    } else {
      set(feature, 'hasError', false);
    }
  }

  clearEmployeeErrors() {
    this.employeeError = [];
    this.setDeskHasError();
  }

  /**
   * Employee related functions
   **/

  get employeesToShow() {
    if (this.searchedEmployees.length) {
      return this.searchedEmployees;
    }
    return this.args.additionalDetails.employees;
  }

  onEmployeeSearchTask = task({ restartable: true }, async (searchTerm) => {
    if (searchTerm) {
      await this.employeesSearcherTask.perform(searchTerm);
    }
    const validEmployee = this.searchedEmployees.find(
      (employee) => searchTerm.toLowerCase().trim() === employee.name?.toLowerCase(),
    );
    const selectedDesk = this.selectedDeskResource;

    if (validEmployee) {
      this.updateDeskWithNewAssignee(selectedDesk, validEmployee);
      this.clearEmployeeErrors();
    } else if (searchTerm === '') {
      this.searchedEmployees = [];
      this.selectedEmployee = ' ';

      // get the employee that was assigned to the desk, so we can update that employee in the overview panel
      const employee = this.store.peekAll('employee').find((employee) => selectedDesk.assignedTo === employee.email);

      this.editDesk(this.selectedDeskResource, 'assignedTo', '');

      // if a desk is unassigned from someone, removing any pending unassignments
      const pendingAssignment = this.selectedDeskResource.assignmentEmployeeId();
      if (pendingAssignment == PENDING_UNASSIGNMENT) {
        this.editDesk(this.selectedDeskResource, 'assignmentDetails', []);
        this.editDesk(this.selectedDeskResource, 'assignments', []);
      }

      if (employee) {
        const assignedDesks = this.getAssignedDesksForEmployee(employee.id).filter(
          (it) => parseInt(it.id) !== parseInt(selectedDesk.id),
        );
        this.updateEmployeeOverviewPanel(employee.id, assignedDesks);
      }

      this.clearEmployeeErrors();
    } else {
      this.editDesk(this.selectedDeskResource, 'assignedTo', searchTerm);
      this.employeeError = ["Employee doesn't exist"];
      this.setDeskHasError();
    }
  });

  @(employeesSearcherTask({
    filter: {
      deleted: false,
    },
    prefix: true,
  }).restartable())
  _searchEmployeesTask;

  employeesSearcherTask = task({ restartable: true }, async (term) => {
    const extraFilters = {
      locations: this.state.currentLocation.id,
      deleted: false,
    };
    this.searchedEmployees = await this._searchEmployeesTask.perform(term, extraFilters);
  });

  employeesOtherAssignedDesks(employee) {
    if (employee) {
      return this.getAssignedDesksForEmployee(employee.id).filter(
        (it) => parseInt(it.id) !== parseInt(this.selectedDeskResource.id),
      );
    } else {
      return [];
    }
  }

  updateDeskWithNewAssignee(desk, employee) {
    const employeeGainingDesk = employee;
    const employeeLosingDesk = this.store.peekAll('employee').find((employee) => employee.email === desk.assignedTo);

    // number of desks the employee that is losing the desk is assigned to, EXCLUDING the desk they are losing
    const losingEmployeesOtherDesks = this.employeesOtherAssignedDesks(employeeLosingDesk);
    const gainingEmployeesOtherDesks = this.employeesOtherAssignedDesks(employeeGainingDesk);

    const floorId = this.args.feature.belongsTo('mapFloor').id();
    const floorName = this.store.peekRecord('map-floor', floorId).name;
    const deskToAdd = this.resourceOverview.createGQLDesk(desk.id, desk.name, floorName);
    if (employeeLosingDesk) {
      this.updateEmployeeOverviewPanel(employeeLosingDesk.id, losingEmployeesOtherDesks);
    }
    this.updateEmployeeOverviewPanel(employeeGainingDesk.id, gainingEmployeesOtherDesks.concat(deskToAdd));

    this.editDesk(desk, 'assignedTo', employee.email);
  }

  @action
  onEmployeeSelect(option) {
    if (option !== 'loadMore') {
      this.updateDeskWithNewAssignee(this.selectedDeskResource, option);
      this.selectedEmployee = option.name;
      this.clearEmployeeErrors();
    }
  }

  validateAssignedEmployeeTask = task({ restartable: true }, async () => {
    this.clearEmployeeErrors();
    if (this.selectedDeskResource.assignedTo) {
      const employeeEmail = this.selectedDeskResource.assignedTo.toLowerCase().trim();
      const employees = await this.store.query('employee', {
        filter: { deleted: false, email: employeeEmail, locations: this.state.currentLocation.id },
      });
      const employee = employees.find((employee) => employee.email === this.selectedDeskResource.assignedTo);
      if (employee) {
        this.selectedEmployee = employee.name;
      } else {
        this.selectedEmployee = null;
        this.employeeError = ["Employee doesn't exist"];
        this.setDeskHasError();
      }
    }
  });

  onEmployeeFocusOutTask = task({ drop: true }, async () => {
    this.validateAssignedEmployeeTask.perform();
  });

  setSelectedEmployeeTask = task({ restartable: true }, async () => {
    if (this.selectedDeskResource.assignedTo) {
      const employeeEmail = this.selectedDeskResource.assignedTo.toLowerCase().trim();
      const employees = await this.store.query('employee', {
        filter: { deleted: false, email: employeeEmail, locations: this.state.currentLocation.id },
      });
      const employee = employees.find((employee) => employee.email === this.selectedDeskResource.assignedTo);
      this.selectedEmployee = employee?.name;
    } else {
      this.selectedEmployee = null;
    }
  });

  /**
   * Neighborhood related functions
   **/

  get searchedNeighborhoods() {
    const { neighborhoods } = this.args.additionalDetails;
    const searchedNeighborhoods = neighborhoods.filter((neighborhood) =>
      neighborhood.name.toLowerCase().includes(this.neighborhoodSearchValue.toLowerCase().trim()),
    );

    const neighborhoodOptions = [...searchedNeighborhoods];

    if (this.abilities.can('create-neighborhood desks')) {
      neighborhoodOptions.push('create');
    }

    return neighborhoodOptions;
  }

  get createNeighborhoodValue() {
    const isValidNeighborhood = this.findValidNeighborhood(this.neighborhoodSearchValue);
    return isValidNeighborhood ? '' : this.neighborhoodSearchValue;
  }

  get scheduledEmployeeName() {
    const employeeId = this.selectedDeskResource?.assignmentEmployeeId();
    if (employeeId == PENDING_UNASSIGNMENT) {
      return employeeId;
    } else if (employeeId) {
      return this.store.peekRecord('employee', employeeId).name;
    } else {
      return '';
    }
  }

  get scheduledDate() {
    const date = this.selectedDeskResource?.assignmentStartDate();
    if (date) {
      return new Date(date).toLocaleString('default', {
        timeZone: this.state.currentLocation.timezone,
        month: 'long',
        day: 'numeric',
        year: 'numeric',
      });
    } else {
      return null;
    }
  }

  get hasAssignments() {
    return this.selectedDeskResource.assignmentDetails?.length > 0 || this.selectedDeskResource.assignments.length > 0;
  }

  @dropTask
  showEditDeskAssignmentModalTask = {
    *perform() {
      const deferred = defer();
      this.abort = () => {
        deferred.resolve(false);
      };
      this.continue = async () => {
        this.context.metrics.trackEvent('Edit Desk Assignment Modal Launched', {
          userId: this.context.state.currentUser?.id,
          deskId: this.selectedDeskResource?.id,
        });
        deferred.resolve(true);
      };

      return yield deferred.promise;
    },
  };

  @dropTask
  showDeskAssignmentModalTask = {
    *perform() {
      const deferred = defer();
      this.abort = () => {
        deferred.resolve(false);
      };
      this.continue = async () => {
        this.context.metrics.trackEvent('Schedule Desk Assignment Modal Launched', {
          userId: this.context.state.currentUser?.id,
          deskId: this.context.selectedDeskResource?.id,
        });
        deferred.resolve(true);
      };
      return yield deferred.promise;
    },
  };

  @dropTask
  showCreateNeighborhoodModalTask = {
    *perform() {
      const deferred = defer();
      this.abort = () => {
        deferred.resolve(false);
      };
      this.continue = async () => {
        deferred.resolve(true);
      };
      return yield deferred.promise;
    },
  };

  @action
  onNeighborhoodSelect(option) {
    if (option === 'create') {
      return;
    }

    const neighborhoodName = this.selectedDeskResource?.neighborhoodName;
    const prevNeighborhood = this.findValidNeighborhood(neighborhoodName || '');

    if (prevNeighborhood) {
      set(prevNeighborhood, 'numberOfDesks', prevNeighborhood.numberOfDesks - 1);
    }

    this.editDesk(this.selectedDeskResource, 'neighborhood', option);
    set(option, 'numberOfDesks', option.numberOfDesks + 1);
    this.clearNeighborhoodErrors();
  }

  @action
  onNeighborhoodSearch(value) {
    this.neighborhoodSearchValue = value;
    const neighborhoodName = this.selectedDeskResource?.neighborhoodName;
    const prevNeighborhood = this.findValidNeighborhood(neighborhoodName || '');

    if (prevNeighborhood) {
      set(prevNeighborhood, 'numberOfDesks', prevNeighborhood.numberOfDesks - 1);
    }
    if (value !== '') {
      const validNeighborhood = this.findValidNeighborhood(value);
      if (validNeighborhood) {
        this.editDesk(this.selectedDeskResource, 'neighborhood', validNeighborhood);
        set(validNeighborhood, 'numberOfDesks', validNeighborhood.numberOfDesks + 1);
        this.clearNeighborhoodErrors();
      } else {
        this.neighborhoodError = ["Neighborhood doesn't exist"];
        this.setDeskHasError();
      }
    } else {
      this.clearNeighborhoodErrors();
      this.editDesk(this.selectedDeskResource, 'neighborhood', null);
    }
  }

  @action
  onNeighborhoodFocusOut() {
    this.validateDeskNeighborhood();
  }

  validateDeskNeighborhood() {
    this.clearNeighborhoodErrors();
    const neighborhoodName = this.selectedDeskResource?.neighborhoodName;
    const validNeighborhood = this.findValidNeighborhood(neighborhoodName || '');

    if (!validNeighborhood && neighborhoodName != null) {
      this.neighborhoodError = ["Neighborhood doesn't exist"];
      this.setDeskHasError();
    }
  }

  findValidNeighborhood(name) {
    const { neighborhoods } = this.args.additionalDetails;
    if (name) {
      return neighborhoods.find((neighborhood) => name.toLowerCase().trim() === neighborhood.name.toLowerCase());
    }
    return null;
  }

  @action
  onCreateNeighborhood(neighborhood) {
    const { neighborhoods } = this.args.additionalDetails;
    this.editDesk(this.selectedDeskResource, 'neighborhood', neighborhood);
    set(neighborhood, 'numberOfDesks', 1);
    neighborhoods.pushObject(neighborhood);
    this.clearNeighborhoodErrors();
  }

  clearNeighborhoodErrors() {
    this.neighborhoodError = [];
    this.setDeskHasError();
  }

  /**
   * Amenity related functions
   **/

  get deskAmenities() {
    return !this.selectedDeskResource ||
      this.selectedDeskResource.isDeleted ||
      this.selectedDeskResource.amenities.isDestroyed
      ? []
      : this.selectedDeskResource.amenities.toArray();
  }

  @action
  setSelectedAmenities(amenities) {
    this.selectedAmenities = amenities;
    this.editDesk(this.selectedDeskResource, 'amenities', this.selectedAmenities);
  }

  // This function is need for the validateUserInput param in the comboBox.
  // The default for the param is a function that returns true.
  @action
  returnFalseValid() {
    return false;
  }
}

export default DeskForm;
