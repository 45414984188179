import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { isBlank } from '@ember/utils';
import { alias, equal, or } from 'macro-decorators';

export default class VisitorTypesFlowController extends Controller {
  @service abilities;
  @service featureFlags;
  @service('global-setting-batch') globalSettingBatchService;
  @service router;
  @service currentAdmin;

  @alias('globalSettingBatchService.currentGlobalSettingBatch') globalSettingBatch;
  @equal('router.currentRouteName', 'visitors.settings.visitor-types.flow.legal-documents.edit')
  onEditLegalDocumentRoute;
  @equal('router.currentRouteName', 'visitors.settings.visitor-types.flow.legal-documents.new') onNewLegalDocumentRoute;
  @or('onEditLegalDocumentRoute', 'onNewLegalDocumentRoute') onNewOrEditLegalDocumentRoute;
  @equal('localFlows.length', 1) singleLocationFlow;
  @or('currentAdmin.isGlobalAdmin', 'currentAdmin.isLocationAdmin') isAdmin;

  get localFlows() {
    const { allFlowsForCurrentLocation: flows } = this.model;
    return flows.filter(
      ({ type, employeeCentric }) => (isBlank(type) || type.toLowerCase() === 'flows') && !employeeCentric,
    );
  }

  get canEditGlobalFlow() {
    return this.featureFlags.isEnabled('custom-roles-garaje-authz-vis3-flows')
      ? this.abilities.can('edit global-flows')
      : this.currentAdmin.isGlobalAdmin;
  }

  get showSetupGuide() {
    return this.isAdmin && this.featureFlags.isEnabled('growth_show_visitors_setup_guide_stepper');
  }

  @action
  transitionToFlow(flow) {
    this.transitionToRoute(this.router.currentRouteName, flow.id); // eslint-disable-line ember/no-deprecated-router-transition-methods
  }
}
