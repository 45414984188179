import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action, get, set } from '@ember/object';
import { all, dropTask } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { IMPRESSION_NAMES } from 'garaje/utils/enums';

const integration_name = 'Visual Compliance';
const message_code_type = 'integration';

/**
 * @param {Boolean}                                         isOpen
 * @param {Function}                                        toggle
 * @param {Class<Location>}                                 location
 * @param {Class<Subscription>}                             subscription
 * @param {Class<VisualComplianceConfiguration>}            visualComplianceConfiguration
 * @param {Function}                                        onCreateConfiguration
 * @param {Function}                                        onUpgrade
 */
export default class VisualCompliancePanel extends Component {
  @service featureFlags;
  @service flashMessages;
  @service impressions;
  @service metrics;
  @service store;

  @tracked configuration = null;
  @tracked confirmUninstall = false;

  constructor() {
    super(...arguments);
    this.configuration = this.args.visualComplianceConfiguration;
  }

  @dropTask
  *installTask() {
    this.metrics.startJob();
    this.metrics.trackJobEvent('Security - Go to Integration Section Button Clicked', {
      integration_name,
      button_text: 'Install',
    });
    try {
      if (!this.configuration) {
        const config = this.store.createRecord('visual-compliance-configuration', { location: this.args.location });
        yield config.save();
        this.configuration = config;
        this.args.onCreateConfiguration(config);
      }
      set(this.args.location, 'visualComplianceEnabled', true);
      this.metrics.trackJobEvent('Security - Integration Install Requested', { integration_name });
      yield this.args.location.save();
      this.metrics.trackJobEvent('Security - Integration Installed', { integration_name });
      this.args.toggle(true);
      this.flashMessages.showAndHideFlash('success', 'Installed!');
      this.metrics.trackJobEvent('Viewed Flash Message', {
        type: 'success',
        integration_name,
        message_title: 'Installed!',
        message_code: 'integration_install_success',
        message_code_type,
      });
      yield this.impressions.postImpression.perform(
        IMPRESSION_NAMES.SECURITY_VR_SCREENING_OPTIONS_VISUAL_COMPLIANCE['ENABLED'],
      );
    } catch (e) {
      this.args.location.rollbackAttributes();
      this.configuration.rollbackAttributes();
      this.args.toggle(false);
      const message_title = 'There was an issue installing Visual Compliance';
      const message_code = parseErrorForDisplay(e);
      this.flashMessages.showFlash('error', message_title, message_code);
      this.metrics.trackJobEvent('Viewed Flash Message', {
        type: 'error',
        integration_name,
        message_title,
        message_code,
        message_code_type,
      });
    }
  }

  @dropTask
  *uninstallTask() {
    this.confirmUninstall = false;
    this.metrics.trackJobEvent(`Security - Integration Uninstall Button Clicked`, {
      integration_name,
      button_text: 'Uninstall',
    });

    const flows = get(this.args.location, 'flows') ?? [];
    let flowConfigurations = yield all(flows.map((flow) => get(flow, 'visualComplianceConfiguration')));
    flowConfigurations = flowConfigurations.filter(Boolean);

    flowConfigurations.map((config) => set(config, 'fieldsToCheck', ['name']));
    set(this.args.location, 'visualComplianceEnabled', false);
    this.args.toggle(false);

    try {
      this.metrics.trackJobEvent('Security - Integration Uninstall Requested', { integration_name });
      yield all([this.args.location, ...flowConfigurations].map((record) => record.save()));
      this.metrics.trackJobEvent('Security - Integration Uninstalled', { integration_name });
      this.flashMessages.showAndHideFlash('success', 'Uninstalled!');
      this.metrics.trackJobEvent('Viewed Flash Message', {
        type: 'success',
        integration_name,
        message_title: 'Uninstalled!',
        message_code: 'integration_uninstall_success',
        message_code_type,
      });
      yield this.impressions.postImpression.perform(
        IMPRESSION_NAMES.SECURITY_VR_SCREENING_OPTIONS_VISUAL_COMPLIANCE['DISABLED'],
      );
    } catch (e) {
      this.args.location.rollbackAttributes();
      flowConfigurations.map((config) => config.rollbackAttributes());
      this.args.toggle(true);
      const message_title = 'There was an issue uninstalling Visual Compliance';
      const message_code = parseErrorForDisplay(e);
      this.flashMessages.showAndHideFlash('error', message_title, message_code);
      this.metrics.trackJobEvent('Viewed Flash Message', {
        type: 'error',
        integration_name,
        message_title,
        message_code,
        message_code_type,
      });
    }
  }

  @action
  onCloseVisualCompliance() {
    this.args.toggle(false);
    this.metrics.trackJobEvent('Security - Integration Section Close Button Clicked', {
      integration_name,
      button_text: 'X',
    });
  }

  @action
  onClickConfigure() {
    this.metrics.startJob();
    this.metrics.trackJobEvent('Security - Go to Modify Integration Settings Button Clicked', {
      integration_name,
      button_text: 'Configure',
      credentials_status:
        this.args.visualComplianceConfiguration.credentialsInvalid && this.args.visualComplianceConfiguration.secno
          ? 'invalid'
          : 'valid',
    });
    this.args.toggle(true);
  }

  @action
  onClickUpgrade() {
    this.metrics.startJob();
    this.metrics.trackJobEvent('Security - Integration Upgrade Button Clicked', {
      integration_name,
      button_text: 'Upgrade',
      plan_upgrade_target: 'enterprise',
    });
    this.args.onUpgrade && this.args.onUpgrade();
  }
}
